body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.border-radius-50 {
  border-radius: 50px !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  color: #0e2043;
}

.blockquote-footer::before {
  content: none !important;
}

.carousel-control-next,
.carousel-control-prev {
  color: #faa652 !important;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 80px !important;
  width: 80px !important;
}

header,
#search-police-station {
  width: 100%;
  position: relative;
}
Carousel,
#header-content-wrapper,
#search-police-station-map,
#search-police-station-content-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#header-content-wrapper,
#search-police-station-content-wrapper {
  z-index: 10;
}

.font-size-changer .rounded-circle {
  width: 40px;
  height: 40px;
}

.font-size-up {
  margin-right: 10px;
}

#font-size-dropdown::after {
  content: none;
}

.primary-navbar {
  background-color: #fff;
  height: 56px;
  width: 100%;
}

@media (max-width: 992px) {
  .Typist {
    font-size: 1rem !important;
  }
  .react-select-search-police-staion {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    margin: 1rem 0 !important;
    max-width: 100% !important;
  }
  .carousel-indicators {
    z-index: 1;
  }
  .primary-navbar.rounded-pill {
    border-radius: 0 !important;
  }
  .navbar-collapse {
    z-index: 1;
    align-items: center;
    padding: 8px 0;
  }

  .navbar-collapse .navbar-nav {
    background: #faa652;
    align-items: flex-end;
    padding: 1rem 2rem;
    width: 100%;
  }

  .navbar-collapse .dropdown-item:focus,
  .navbar-collapse .dropdown-item:hover {
    background-color: #fef3e7;
  }
  .navbar-collapse .dropdown-menu {
    background-color: #fdd9b5;
  }

  .navbar-collapse .nav-item {
    border-bottom: 1px solid #fff;
    width: 100%;
    text-align: right;
  }

  .navbar-collapse .nav-item:last-child {
    border-bottom: none;
  }

  #secondary-navbar {
    height: 175px !important;
    text-align: right;
    display: inline-grid;
    display: -ms-inline-grid;
    display: -moz-inline-grid;
    width: auto !important;
  }

  #secondary-navbar .nav-item {
    width: 100%;
  }

  .navbar-brand div {
    z-index: 2;
  }

  #global-search {
    max-width: 100vw !important;
  }

  #secondary-navbar {
    margin: 0 !important;
    padding: 1rem !important;
    background-color: transparent !important;
  }

  #secondary-navbar:after {
    opacity: 0.7;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #faa652;
    z-index: -1;
  }

  #secondary-navbar:before {
    content: none !important;
  }
  #secondary-navbar .nav-item {
    width: 100%;
    margin: 0;
  }
  .header-carousel .carousel-item {
    height: 75vh !important;
  }

  .header-carousel .carousel-item img {
    min-height: 75vh !important;
  }
  #global-search-wrapper {
    height: 75vh !important;
  }
  #global-search {
    width: 80% !important;
    position: absolute;
    bottom: 4rem;
  }

  #footer-image {
    height: 150px !important;
  }
  .page-link .btn {
    font-size: 0.5rem !important;
  }
  .page-link {
    padding: 0 !important;
  }
}

#secondary-navbar .nav-item {
  color: #fff;
  font-weight: 500;
}

#contact-navbar-option {
  background-color: #f4bc64;
}

.header-carousel .carousel-item {
  height: 100vh;
}

.header-carousel .carousel-item img {
  min-height: 100vh;
}

#secondary-navbar {
  background-color: #faa652;
  color: #383185;
  height: 78px;
  margin-top: -1.5rem;
  position: relative;
  padding: 0 78px;
  padding-top: 1.5rem;
  z-index: -1;
}

#secondary-navbar:before {
  content: "";
  position: absolute;
  left: -78px;
  top: 0;
  border-bottom: 78px solid #faa652;
  border-left: 100px solid transparent;
}

#global-search-wrapper {
  position: absolute;
  top: 0;
  height: 100vh;
  align-items: center;
  z-index: -1;
}

#global-search {
  background-color: #fff;
  padding: 2px;
  opacity: 0.7;
  height: fit-content;
  max-width: 600px;
}

#global-search-inut {
  border: none;
  height: auto;
  margin-right: -45px;
  z-index: -1;
}

#global-search-button {
  border: none;
  background: none;
}

#quick-links-wrapper,
#special-mentions-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

#gallery-wrapper {
  padding-bottom: 2rem;
}

#gallery-wrapper .carousel-indicators {
  bottom: -4rem;
}

#gallery-wrapper .carousel-indicators li {
  background-color: #faa652;
}

#bravery-wrapper {
  padding-bottom: 2rem;
}

#bravery-wrapper .carousel-indicators {
  bottom: -4rem;
  background-color: #faa652 !important;
  border-color: #faa652 !important;
}

#bravery-wrapper .carousel-indicators li {
  background-color: #faa652 !important;
}

.quick-links-image-wrapper {
  width: 148px;
  height: 148px;
  padding: 40px;
  background-color: #faa652;
}

#from-cp-office {
  background-color: #f8f8f8;
  margin-top: 10px;
  margin-bottom: 10px;
}

#cp-image,
.special-mention-images,
.sp-section-images {
  margin-left: auto;
  margin-right: auto;
}

.sp-section-images {
  width: 100%;
  max-width: 450px;
}

.special-mention-section,
.sp-section {
  position: relative;
  width: 300px;
  max-width: 100%;
  margin: auto;
}

.sp-section {
  width: 350px;
}
.pune-police-btn {
  border: none !important;
  padding: 1rem 2rem !important;
  background-color: #faa652 !important;
  color: #fff !important;
}

.page-item.active .page-link {
  background-color: #faa652 !important;
  border-color: #faa652 !important;
  color: #fff !important;
}

#contact-cp,
.special-mention-buttons,
.sp-section-buttons {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #faa652 !important;
  color: #fff !important;
  border: none !important;
  padding: 1rem 2rem !important;
}

#search-police-station-content-wrapper .container {
  background-color: #fff;
}

#search-police-station-content-wrapper {
  padding: 1rem;
  width: 355px;
  height: 328px;
  max-width: 100%;
  margin: auto;
}

#search-police-station-inputgroup {
  background-color: #fff;
  padding: 2px;
  opacity: 0.7;
  height: fit-content;
  border: 1px solid #707070;
}
#search-police-station-inut {
  border: none;
}
#search-police-station-button {
  border: none;
  background: none;
}

.gallery-section .gallery-section-wrapper {
  position: relative;
  max-width: 100%;
}

.gallery-section-wrapper img {
  max-width: 100%;
  max-height: 50vh;
}

.dashboard-gallery-blockquote h6,
.gallery-section-blockquote {
  position: absolute;
  bottom: 1rem;
}

.dashboard-gallery-blockquote h6,
.gallery-section-blockquote .carousel-caption,
.dashboard-gallery-blockquote h6 {
  color: #fff;
  font-size: 1rem;
  margin: 0 0.5rem !important;
}

.dashboard-gallery-blockquote h6,
.gallery-section-blockquote footer {
  font-weight: 300;
  font-size: 1rem;
}

#footer-image {
  width: 100%;
  object-fit: fill;
  height: 250px;
}
#footer-navbar,
#footer-navbar-2 {
  min-height: 56px;
  background-color: #faa652;
}
#footer-nav-options,
#footer-nav-options a {
  background-color: #0e2043;
  color: #fff !important;
}
#footer-nav-options p {
  font-size: 1.5rem;
  color: #fff !important;
}
#footer-nav-options ul li {
  font-size: 1rem;
  opacity: 0.5;
}
#footer-nav-options ul {
  list-style-type: none;
  padding: 0px;
}

#police-station-map {
  height: 80vh;
  width: 100%;
  border: none;
  margin: auto;
}

#search-SearchBoxInput-station {
  position: relative;
}

.react-select-search-police-staion {
  position: absolute;
  top: 1rem;
  left: 3rem;
  width: 100%;
  max-width: 350px;
  z-index: 999;
  padding: 2rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 14px;
}

.react-select-search-police-staion p {
  font-size: 1rem;
  color: #292323;
  margin: 0;
}

.react-select-search-police-staion span {
  font-size: 1rem;
  color: #232429;
  text-transform: lowercase;
  opacity: 0.5;
}

.react-select-search-police-staion .address {
  font-size: 0.8rem;
  color: #232429;
  text-transform: lowercase;
  opacity: 0.5;
}

.react-select-search-police-staion .addressName {
  font-size: 1rem;
  color: #292323;
  margin: 0;
  margin-top: 1rem;
}

.speech-bubble {
  display: block;
  background: 0% 0% no-repeat padding-box padding-box rgb(229, 229, 229);
  padding: 10px;
  margin-top: 20px;
  border-radius: 8px;
  min-height: 68px;
}

.speech-bubble::before {
  content: "";
  position: absolute;
  left: -8px;
  bottom: 54px;
  border-right: 30px solid #e5e5e5;
  border-right-color: #e5e5e5;
  border-bottom: 30px solid transparent;
}

#special-mentions-wrapper {
  object-fit: fill;
}
.special-mentions-title {
  text-align: center;
  letter-spacing: 0px;
  color: #292323;
  margin-top: 1.5rem;
}

.special-mentions-subtitle {
  text-align: center;
  letter-spacing: 0px;
  color: #959595;
}

.empty-body {
  height: 100px;
}

.gallery-section-wrapper .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 0;
  left: 5%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: left;
}

.Typist {
  padding: 0.375rem 0.75rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

.fade {
  opacity: 1 !important;
}

.react-select > div {
  margin: -1px;
}

#canv {
  width: 50%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: #ffffff86 0% 0% no-repeat padding-box;
  border: 1px solid #949699;
  border-radius: 36px;
}

.captcha-wrapper div div {
  width: 50%;
  display: flex;
  justify-content: center;
}

.captcha-wrapper div div a {
  padding: 0 1rem;
}

.captcha-wrapper {
  margin-bottom: 1rem;
  flex: 1;
}

.captcha-wrapper div {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.footer-logo {
  height: 120px;
  background-color: #0e2043;
}
.ticker {
  height: 120px !important;
}

.footer-logo img {
  width: 100%;
  max-height: 80px;
}

.footer-logo a {
  width: 100px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.form-wrapper .form-group textarea,
.form-wrapper .form-group input {
  background: #ffffff86 0% 0% no-repeat padding-box;
  border: 1px solid #949699;
  border-radius: 36px;
  opacity: 1;
}

.btn.btn-primary {
  background: #faa652 0% 0% no-repeat padding-box;
  border-radius: 36px;
  border-color: #faa652;
  opacity: 1;
}

.btn.btn-secondary {
  background: #faa6524f 0% 0% no-repeat padding-box;
  border-radius: 36px;
  border-color: #faa652;
  opacity: 1;
  color: #000;
}

.lost-and-found #canv {
  font-size: 1.5rem;
  border-radius: 0;
  width: 80%;
}

.lost-and-found #reload_href {
  color: #212529 !important;
}

.lost-and-found #reload_href {
  visibility: hidden;
}
.lost-and-found #reload_href::after {
  content: "";
  width: 50px;
  height: 50px;
  visibility: visible;
  position: absolute;
  top: 0;
  display: block;
  background: url("./reload.png");
  background-size: 50px;
}

@media (hover: none), (pointer: coarse), (-moz-touch-enabled: 1) {
  #OrganizationalStructure {
    height: 1000px !important;
    width: 2000px !important;
  }
  .OrganizationalStructure {
    overflow: auto !important;
  }

  .scroll-horizontal {
    overflow: scroll !important;
    overflow: overlay !important;
    overflow-x: scroll !important;
    overflow-x: overlay !important;
    overflow-y: hidden !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }

  .scroll-horizontal > div {
    transform: none !important;
    position: relative;
  }

  .scroll-horizontal::-webkit-scrollbar {
    display: none !important;
  }
}

.other-page button {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.form-check .invalid-feedback {
  display: none !important;
}

.search-card .card:hover {
  box-shadow: 0px 0px 6px #00000029;
}

#bravery-wrapper #global-search,
#bravery-wrapper #global-search input {
  background-color: #faa652 !important;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.opt-input input {
  width: 1.5rem !important;
  height: 1.5rem !important;
  font-size: 1rem;
  margin: 0 1rem;
  font-weight: 400;
  color: #495057;
  border-radius: 4px !important;
  border: 1px solid #949699;
  background: #ffffff86 0% 0% no-repeat padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (min-width: 768px) {
  .opt-input input {
    width: 3rem !important;
    height: 3rem !important;
    font-size: 2rem;
  }

  .speech-bubble {
    font-size: 1.5rem;
  }
  
  .speech-bubble::before {
    left: -20px;
    border-right: 45px solid #e5e5e5;
    border-bottom: 45px solid transparent;
  }
}

#print h3 {
  font-size: 1rem !important;
}

#print table {
  width: 60vw;
}

@media (max-width: 992px) {
  #print table {
    width: 80vw;
  }
  .sing img {
    height: 212px;
  }
}

.sign {
  position: relative;
}

.sing img {
  position: absolute;
  top: 0;
}

.bk {
  position: absolute;
  top: 0;
  padding: 20px;
}

#print {
  max-width: 800px;
}

.sign-container * {
  max-width: 100% !important;
}

@media print {
  .noprint {
    display: none;
  }
  #print {
    width: 1000px;
    max-width: 1000px;
    page-break-after: always;
  }
}

.hide-mobile {
  display: none !important;
}
.show-mobile {
  display: block !important;
}

.mySafePune img {
  max-width: 100%;
  margin: auto;
}

.mySafePune .checked {
  color: orange;
}

.mySafePune .headerNav {
  height: auto;
  background: 0% 0% no-repeat padding-box padding-box rgb(250, 166, 82);
  display: flex;
  align-items: center;
  overflow: auto;
  font-size: 1rem;
  padding: 1rem;
}
.headerImg .img2 {
  display: none;
}
.headerImg .img1 {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.saying {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.saying-wrapper {
  flex-direction: column;
}
.sayingImg {
  width: 100px;
}
.overview-section {
  flex-direction: column;
}
.overview-section-1 {
  width: 100%;
}
.overview-section-2 {
  width: 100%;
  margin-top: 100px;
}
.conclusion-section-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: baseline;
}
.conclusion-section {
  padding: 2rem;
  max-width: 350px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.conclusion-section img {
  width: 6rem;
  height: 6rem;
}
.needforproject .img1 {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  float: left;
}
.needforproject .img2 {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
  float: right;
  margin: 50px 0;
}
.needforproject .img3 {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
}
.mysafepunelogo {
  width: 10rem;
}
.mysafepunelogotext {
  text-align: center;
  letter-spacing: 0px;
  color: rgb(255, 255, 255);
  font-size: 1rem;
}
.navlinkitem {
  font-weight: bold;
  letter-spacing: 0px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  flex: 1 1 0%;
  font-size: 1rem;
  text-align: center;
  min-width: 200px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
/* .nextpresec {
  display: none;
} */

.systemoptimisiedsec {
  text-align: center;
  letter-spacing: 0px;
  color: rgb(174, 174, 174);
  margin: 4rem 0px;
}
.systemsec {
  font-size: 3rem;
  opacity: 0.15;
  margin: -1rem 0px;
  text-transform: uppercase;
}
.systemoptimisiedmiddlesec {
  font-size: 1rem;
  color: rgb(0, 0, 0);
  opacity: 1;
  font-weight: bold;
}
.optimisiedsec {
  font-size: 3rem;
  opacity: 0.15;
  margin: -1rem 0px;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) {
  .conclusion-section img {
    width: 10rem;
    height: 10rem;
  }
  .systemsec {
    font-size: 6rem;
    margin: -2.5rem 0px;
  }
  .systemoptimisiedmiddlesec {
    font-size: 2rem;
  }
  .optimisiedsec {
    font-size: 6rem;
    margin: -2.5rem 0px;
  }

  .nextpresec {
    display: block;
  }
  .mySafePune img {
    margin: initial;
  }
  .needforproject .img1 {
    width: 499px;
    height: 499px;
  }
  .needforproject .img2 {
    width: 314px;
    height: 314px;
    margin-top: 50px;
  }
  .needforproject .img3 {
    width: 351px;
    height: 351px;
    margin-top: 50px;
    margin-left: 50px;
  }
  .mysafepunelogo {
    width: 15rem;
  }
  .mysafepunelogotext {
    text-align: center;
    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    font-size: 2rem;
  }
  .overview-section-1 {
    width: 40%;
  }
  .overview-section-1 img {
    width: 40%;
    height: 60%;
  }
  .overview-section-2 {
    width: 40%;
    margin-top: 50px;
  }
  .overview-section {
    flex-direction: row;
  }
  .saying {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .sayingImg {
    width: auto;
  }
  .saying,
  .saying-wrapper {
    flex-direction: row;
  }
  .hide-mobile {
    display: flex !important;
  }
  .show-mobile {
    display: none !important;
  }
  .mySafePune .headerNav {
    background: 0% 0% no-repeat padding-box padding-box rgb(250, 166, 82);
    display: flex;
    align-items: center;
  }
  .headerImg .img2 {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .headerImg .img1 {
    height: auto;
    width: 60%;
  }
}

.point-text {
  letter-spacing: 0.15px;
  color: rgb(113, 113, 113);
  padding: 1rem;
  font: 15px / 26px Montserrat;
}

@media (max-width: 768px) {
  .blockquote {
    font-size: 1rem;
  }
  .blockquote h2 {
    font-size: 1.5rem;
  }
  .point-text {
    font: 12px / 21px Montserrat;
  }
}
