.civil-speach-content {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    opacity: 1;
    /*padding-left: 80px !important;*/
    /*padding-right: 10px !important;*/
    padding: 10px;
    margin-right: 25px;
}

.civil-speach-card-container {
    height: 1100px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill,minmax(160px, 1fr));
    overflow-x: scroll;
}

.civil-speach-card {
    min-height: 300px !important;
    /*min-width: 266px !important;*/
    /*width: 266px;*/
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 76px #00000029;
    border-radius: 13px;
    opacity: 1;
    margin: 20px;
}

.avatar-contianer {
    width: 58px;
    height: 58px;
    background: #B2CFDE 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.avatar-content {
    text-transform: uppercase;
    height: 15px;
    /*text-align: center;*/
    font: normal normal 300 16px/31px Nunito;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.thread-card-header {
    text-transform: capitalize;
    text-align: left;
    font: normal normal 300 12px/18px Nunito;
    letter-spacing: 0px;
    font-weight: 600;
    color: #232429;
    opacity: 1;
    margin: 0;
    font-size: 15px;
}

.thread-card-sub-header {
    text-transform: capitalize;
    text-align: left;
    font: normal normal 300 12px/18px Nunito;
    letter-spacing: 0px;
    color: #959595;
    opacity: 1;
    margin: 0;
}

.thread-card-content {
    text-align: left;
    font: normal normal 300 16px/31px Nunito;
    letter-spacing: 0px;
    color: #959595;
    opacity: 1;
    margin-bottom: 10px;
}

.thread-card-hr {
    border: 1px solid #707070;
    opacity: 0.2;
}

.thread-card-quote {
    background: transparent 0% 0% no-repeat padding-box;
    opacity: 1;
}

.thread-card-cp-comment-header {
    text-align: left;
    font: normal normal 300 16px/21px Nunito;
    letter-spacing: 0px;
    color: #959595;
    text-transform: capitalize;
    opacity: 1;
}
.thread-card-cp-comment-content {
    text-align: left;
    font: normal normal 300 16px / 27px Nunito;
    letter-spacing: 0px;
    color: #959595;
    opacity: 1;
    margin-top: 10px;
}


.thread-card-other-user-comment-container {
    position: relative;
    bottom: -20px;
}


.thread-card-other-user-comment-badge {
    width: 26px;
    height: 21px;
    background: transparent url 0% 0% no-repeat padding-box;
    opacity: 0.3;
}

.thread-card-other-user-comment-badge-count {
    background: #EFC066 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    color: white;
    z-index: 10;
    width: 22px;
    font-size: 13px;
    text-align: center;
    position: relative;
    top: -10px;
    left: 11px;
}

.no-padding-no-margin-left-right {
    margin-right: 0 !important;
    margin-left: 0 !important;;
    padding-left: 0 !important;;
    padding-right: 0 !important;;
}

.w-400 {
    width: 400px !important;
}

.w-350 {
    width: 350px !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}
